/**
 * 文件相关操作方法
 */

export default {
    /**
     * 根据文件获取可预览的文件路径
     * @param {File} file
     */
    getFileUrl(file){
        if(!file || !(file instanceof File)){
            throw new TypeError("所传参数必须是一个文件");
        }else{
            return window.URL.createObjectURL(file);
        };
    },
    /**
     * 将File对象转为Base64格式的字符串，返回Promise的对象
     * @param {File} file
     */
    getFileBase64(file){
        return new Promise((resolve,reject)=>{
            if(!file || !(file instanceof File)){
                reject(new TypeError("所传参数必须是一个文件"));
            }else{
                let reader=new FileReader();
                reader.readAsDataURL(file); //读出base64
                reader.onloadend=()=>{
                    let dataURL=reader.result;
                    resolve(dataURL);
                };
            };
        });
    },
    /**
     * 将Base64格式字符串文件转换为File对象
     * @param {String} base64String base64位格式字符串
     * @param {String} fileName 转换后的文件名，包含后缀
     */
    getBase64File(base64String,fileName){
        if(!base64String || typeof base64String !== "string"){
            throw new TypeError("第一个参数必须是一个字符串");
        }else if(!fileName || typeof fileName !== "string"){
            throw new TypeError("第二个参数必须是一个字符串");
        }else{
            let arr=base64String.split(",");
            let mime=arr[0].match(/:(.*?);/)[1];
            let bstr=window.atob(arr[1]);
            let n=bstr.length;
            let u8arr=new Uint8Array(n);
            while(n--){
                u8arr[n]=bstr.charCodeAt(n);
            };
            return new File([u8arr],fileName,{
                file:mime
            });
        };
    }
};