<template>
	<div id="app">
		<keep-alive :exclude="['login']">
			<router-view />
		</keep-alive>
		<a target="_blank" href="https://beian.miit.gov.cn/"
			:style="`display:${$route.path === '/login' ? 'block;' : 'none;'}`"
		>备案号：皖ICP备2022015884号-1</a>
	</div>
</template>
<script>
import jwt_decode from "jwt-decode";
import Qilin from "qilin-utils";
export default {
	provide(){
		return {
			name:"EDGQilin"
		}
	},
	methods:{
		//判断是否为空的通用方法--若为空，则返回true
        isEmpty(value){
            return value === undefined || value === null ||
                (typeof value === "object" && Object.keys(value).length === 0) ||
                (typeof value === "string" && value.trim().length === 0);
        }
	},
	created(){
		// 当地址栏有携带token进入访问时，可直接解析信息进入
		if(this.$Qilin.Common.getUrlParamsValue("qilinToken",location.search)){
			// localStorage.setItem("qilinToken",this.$Qilin.Common.getUrlParamsValue("qilinToken",location.search));
			const userInfo=jwt_decode(this.$Qilin.Common.getUrlParamsValue("qilinToken",location.search)); //解析token，获取token中的信息
			this.$store.dispatch("setAuthenticated",!this.isEmpty(userInfo));
			this.$Qilin.Storage.setKeyValueLocalStorage("avatar","moren");
			this.$store.dispatch("setUserInfo",{...userInfo,image:"moren"});
		};
		//当刷新页面时，需在根组件中重新获取并解析token，使得用户信息存储在vueX里
		if(localStorage.qilinToken){
			const userInfo=jwt_decode(localStorage.qilinToken); //解析token，获取token中的信息
			this.$store.dispatch("setAuthenticated",!this.isEmpty(userInfo));
			this.$store.dispatch("setUserInfo",{...userInfo,image:Qilin.Storage.getValueLocalStorage("avatar")});
		};
	},
	mounted(){
		// 当窗口发生变化时触发事件监听
		window.addEventListener("resize",()=>{
			// console.log(Qilin.Element.getNodeWidth());
			const isCollapse=this.$store.getters["home/getIsCollapse"];
			if(Qilin.Element.getNodeWidth() <= 1000){ //当可视窗口的宽度小于1000px时，导航栏手收缩
				if(!isCollapse){
					this.$store.commit("home/set_isCollapse",!isCollapse);
					this.$store.commit("home/set_width",!isCollapse?4.571:14.286);
				};
			};
		});
	}
}
</script>

<style lang="less">
:root{
	--themeColor:@color-text-common; //字体色
	--themeBgColor:@color-bg-common; //背景色
	--themeBorderColor:@color-border; //边框色
	--shadow:@color-shadow; //阴影色
}
html,body,#app{
    font-size:87.5% !important; //浏览器默认大小为16px，本项目设置根元素默认字体大小为14px
}
// 两种url路径方式都可实现
html,body,#app{
	.EDGQilinWH();
	.EDGQilinBGImage("@/assets/images/pc1.jpeg");
	// .EDGQilinBGImage("./assets/images/pc1.jpeg");
	position:relative;
	font-family: @text-china-default,@text-english-default;
	color:@color-text-common;
	// overflow:hidden;
}
#app{
	>a{
		color:rgba(255,255,255,.65);
		text-decoration: none;
		position:absolute;
		bottom:20px;
		left:50%;
		transform:translateX(-50%);
		font-size:@size-text-small;
	}
}
*{
	.EDGQilinGeneral()
}

@media only screen and (min-width:1921px){
	html,body,#app{
		font-size:120% !important;
	}
}
@media only screen and (min-width:1537px) and (max-width:1920px){
	html,body,#app{
		font-size:100% !important;
	}
}
@media only screen and (max-width:640px){
	html,body,#app{
		font-size:75% !important;
	}
}
</style>
