import axios from "axios";
import {Loading,Message} from "element-ui";
import router from "../router/index.js";

let loading;
function startLoading(){
    loading=Loading.service({
        lock:true,
        text:"请求加载中...",
        background:"rgba(0,0,0,0.7)"
    });
};
function endLoading(){
    loading.close();
};

//请求拦截
axios.interceptors.request.use((requestConfig)=>{
    // console.log(requestConfig); //请求配置项
    startLoading(); //加载动画
    // console.log(localStorage.qilinToken);
    if(localStorage.qilinToken){
        requestConfig.headers.Authorization=localStorage.qilinToken;
    };
    return requestConfig;
},(error)=>{
    return Promise.reject(error);
});

//响应拦截
axios.interceptors.response.use((response)=>{
    // console.log(response); //响应数据体
    endLoading(); //结束加载动画
    return response.data;
},(error)=>{
    // console.log(error.response);
    endLoading(); //结束加载动画
    if(error.response.status === 401){//此时属于token失效
        Message.error({
            message:"token失效，请重新登录!",
            center:true
        });
        localStorage.removeItem("qilinToken"); //清除token
        router.replace("/login");
    }else{
        if(!!error.response.data.message){
            Message.error({ //错误提醒
                message:error.response.data.message,
                center:true
            });
        }else{
            Message.error({
                message:"系统错误",
                center:true
            });
        };
    };
    return Promise.reject(error);
});

export default axios;