<template>
    <div class="qilin-QilinView">
        <el-row class="qilin-QilinView-row">
            <template v-for="(item,index) in ownViewConfig.itemConfig">
                <el-col
                    :key="item.value || 'view-key-'+index "
                    :span="item.col || 12"
                    :class="['qilin-QilinView-col',item.className]"
                >
                    <template v-if="!item.ellipsis">
                        <label class="qilin-QilinView-col-label">
                            <span class="ellipsis">{{item.label || "默认label"}}</span>
                        </label>
                    </template>
                    <template v-else>
                        <el-tooltip
                            :placement="item.ellipsisPlacement || 'left' "
                            :effect="item.ellipsisEffect || 'dark' "
                        >
                            <div slot="content">{{item.label || "默认label"}}</div>
                            <label class="qilin-QilinView-col-label">
                                <span class="ellipsis">{{item.label || "默认label"}}</span>
                            </label>
                        </el-tooltip>
                    </template>
                    <span
                        v-if="!item.valueEllipsis"
                        :class="[
                            'qilin-QilinView-col-value',
                            item.autoValue?'autoValue':''
                        ]"
                    >
                        <template v-if="!item.type || item.type === 'text' ">
                            <span :class="item.textarea?'textarea':''">
                                {{ownViewConfig.viewData[item.value]?ownViewConfig.viewData[item.value]:"-"}}
                            </span>
                        </template>
                        <template v-else-if="item.type === 'image' ">
                            <template v-if="ownViewConfig.viewData[item.value] && ownViewConfig.viewData[item.value].length > 0">
                                <span 
                                    v-for="(ite,ind) in ownViewConfig.viewData[item.value]"
                                    :key="ite.fileName || 'image-key-'+ind "
                                >
                                    <el-image
                                        :src="getImageUrl(ite.fileName)"
                                        :preview-src-list="[getImageUrl(ite.fileName)]"
                                    ></el-image>
                                </span>
                            </template>
                            <span v-else>暂无图片</span>
                        </template>
                        <template v-else-if="item.type === 'slot' ">
                            <slot :name="item.slotName" :data="{item,data:ownViewConfig.viewData[item.value]}"></slot>
                        </template>
                        <template v-else-if="item.type === 'date' ">
                            <span>{{getDate(ownViewConfig.viewData[item.value],item.format || "")}}</span>
                        </template>
                    </span>
                    <template v-else>
                        <el-tooltip
                            :placement="item.valueEllipsisPlacement || 'bottom' "
                            :effect="item.valueEllipsisEffect || 'dark' "
                        >
                            <div slot="content">{{ownViewConfig.viewData[item.value]?ownViewConfig.viewData[item.value]:"-" || "默认label"}}</div>
                            <span class="qilin-QilinView-col-value">
                                <span class="ellipsis">{{ownViewConfig.viewData[item.value]?ownViewConfig.viewData[item.value]:"-" || "默认label"}}</span>
                            </span>
                        </el-tooltip>
                    </template>
                </el-col>
            </template>
        </el-row>
    </div>
</template>
<script>
import globalUtils from "@/utils/index.js";
export default {
    name:"QilinView",
    inject:["uploadImageUrl"],
    props:{
        viewConfig:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return {
            // 组件自身的默认配置
            ownViewConfig:{
                itemConfig:[//展示查看信息配置项
                    {
                        type:"text", //显示数据类型
                        textarea:false, //text类型是否是textarea
                        slotName:"", //slot元素的name，当且仅当type为slot时需要
                        format:"", //显示日期格式配置项，当且仅当type为date时需要
                        label:"姓名", //显示label字段名称
                        value:"name", //显示数据名称--对应viewData
                        col:12, //栅栏格数--默认12
                        className:"", //额外添加在el-col元素上的类名
                        ellipsis:false, //若label名过长是否设置隐藏悬浮显示--默认false
                        ellipsisPlacement:"left", //label超出长度悬浮提示位置
                        ellipsisEffect:"dark", //label超出长度悬浮提示样式类型
                        valueEllipsis:false, //若value数据过长是否设置隐藏悬浮提示--默认false
                        valueEllipsisPlacement:"left", //value数据超出长度悬浮提示位置
                        valueEllipsisEffect:"dark", //value数据超出长度悬浮提示样式类型
                        autoValue:false, //将value数据所在元素设置为正常显示（超出可换行）--默认false
                    }
                ], 
                viewData:{} //动态数据渲染
            }
        }
    },
    computed:{
        getDate(){
            return function(date,format){
                if(!date){
                    return "-";
                };
                return globalUtils.formatToDate(date,format);
            };
        },
        getImageUrl(){
            return function(fileName){
                return !this.$route.fullPath.includes("harem")?
                        this.uploadImageUrl+fileName : 
                        this.uploadImageUrl+"wifes/"+fileName;
            };
        }
    },
    watch:{
        viewConfig:{
            handler(newValue){
                this.initOwnViewConfig(newValue);
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        // 将传来的配置赋值给组件自身
        initOwnViewConfig(target){
            this.ownViewConfig=this.$QilinUtils.deepCopy(target,this.ownViewConfig);
        }
    }
}
</script>
<style lang="less" scoped>
.qilin-QilinView{
    >.qilin-QilinView-row{
        border:1px solid @color-border;
        border-left:none;
        overflow:hidden;
        border-radius:6px;
        >.qilin-QilinView-col{
            border:1px solid @color-border;
            border-left:none;
            border-right:none;
            border-bottom:none;
            margin-top:-1px;
            margin-bottom:0;
            display:flex;
            &:first-of-type{
                border-radius:6px 0 0 0;
                >.qilin-QilinView-col-label{
                    border-radius:6px 0 0 0;
                }
            }
            // &:last-of-type{
            //     margin-bottom:-1px;
            // }
            >.qilin-QilinView-col-label{
                border-left:1px solid @color-border;
                border-right:1px solid @color-border;
                padding: 10px 12px;
                width: 50%;
                background-color:@color-bg-title;
                color:@color-text-title;
                font-weight:bolder;
                display:flex;
                align-items:center;
                &>span.ellipsis{
                    display: inline-block;
                    width:100%;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                }
            }
            >.qilin-QilinView-col-value{
                padding:10px;
                color:@color-text-common;
                width:50%;
                overflow:hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
                >span{
                    margin:0 10px 10px 0;
                    >.el-image{
                        width:80px;
                        height:80px;
                        border-radius:5px;
                    }
                }
                >.textarea{
                    display:inline-block;
                    width:100%;
                    height:100px;
                }
                &.autoValue{
                    white-space: normal;
                }
            }
            &.el-col-24{
                >label{
                    width:16.665%;
                }
                >span{
                    width:83.335%;
                }
            }
            &.el-col-16{
                >label{
                    width:25%;
                }
                >span{
                    width:75%;
                }
            }
            &.el-col-12{
                >label{
                    width:33.333%;
                }
                >span{
                    width:66.667%;
                }
            }
            &.el-col-8{
                >label{
                    width:50%;
                }
                >span{
                    width:50%;
                }
            }
            &.el-col-6{
                >label{
                    width:66.5%;
                }
                >span{
                    width:33.5%;
                }
            }
        }
    }
}
</style>