// 引入组件
import QilinDialog from "./QilinDialog/index.js";
import QilinForm from "./QilinForm/index.js";
import QilinUploadImage from "./QilinUploadImage/index.js";
import QilinSearch from "./QilinSearch/index.js";
import QilinTable from "./QilinTable/index.js";
import QilinView from "./QilinView/index.js";

export default {
    QilinDialog,
    QilinForm,
    QilinUploadImage,
    QilinSearch,
    QilinTable,
    QilinView
}