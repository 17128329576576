import $Qilin from "qilin-utils";

import Drag from "./drag.js";

let $el=null;

export default {
    name:"drag",
    dragDialog:{
        inserted(el,binding){
            // console.log(el,binding);
            let options={};
            if($Qilin.Common.isObject(binding.value)){
                Object.assign(options,binding.value);
            };
            if($Qilin.Common.isObject(binding.modifiers)){
                Object.assign(options,binding.modifiers);
            };
            let drag=new Drag(el,options);
            // 初始化
            drag.init();
            // 将对象记录在元素里
            if(options.$el){ //若要指定拖拽元素
                if($Qilin.Element.isElement(options.$el)){
                    el=options.$el;
                }else if(typeof options.$el === "string" && options.$el){
                    el=document.querySelector(options.$el);
                };
            };
            $el=el;
            $Qilin.Data.setNodeData(el,"directive:drag",drag);
        },
        unbind(el,binding){
            let options={};
            if($Qilin.Common.isObject(binding.value)){
                Object.assign(options,binding.value);
            };
            if($Qilin.Common.isObject(binding.modifiers)){
                Object.assign(options,binding.modifiers);
            };
            // 获取对象
            if(options.$el){ //若要指定拖拽元素
                if($Qilin.Element.isElement(options.$el)){
                    el=options.$el;
                }else if(typeof options.$el === "string" && options.$el){
                    el=$el;
                };
            };
            let drag=$Qilin.Data.getNodeData(el,"directive:drag");
            if(drag){
                // 移除绑定在body上的事件
                drag._setOff();
            };
        }
    }
};