<template>
    <div class="qilin-QilinSearch">
        <el-row>
            <el-col 
                :span="
                    ownSearchConfig.buttonConfig.length === 0 ? 24 : 
                    ownSearchConfig.outerConfig.formCol ? ownSearchConfig.outerConfig.formCol :
                    ownSearchConfig.outerConfig.btnCol ? (24-ownSearchConfig.outerConfig.btnCol) : 20
                "
            >
                <el-form
                    :inline="true"
                    :ref="ownSearchConfig.elFormConfig.refName || 'formSearch' "
                    :label-width="ownSearchConfig.elFormConfig.labelWidth || '6.429rem' "
                    :label-position="ownSearchConfig.elFormConfig.labelPosition || 'right' "
                    :label-suffix="ownSearchConfig.elFormConfig.labelSuffix || '：' "
                    :size="ownSearchConfig.elFormConfig.size || 'small' "
                    :model="ownSearchConfig.submitData"
                >
                    <template v-for="(item,index) in ownSearchConfig.itemConfig">
                        <el-form-item
                            :key="item.dataName || 'form-key-'+index "
                            :label="item.labelName || '搜索标签'+index "
                            :prop="item.dataName"
                            :class="[
                                item.className
                            ]"
                        >
                            <template v-if="item.type === 'slot' ">
                                <slot :name="item.slotName" :data="{item}"></slot>
                            </template>
                        </el-form-item>
                    </template>
                    <el-form-item>
                        <el-button type="primary" circle icon="el-icon-search" @click="searchData()"></el-button>
                        <el-button type="default" circle icon="el-icon-refresh-left" @click="resetSearchData()"></el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col
                :span="
                    ownSearchConfig.buttonConfig.length === 0 ? 0 : 
                    ownSearchConfig.outerConfig.btnCol ? ownSearchConfig.outerConfig.btnCol :
                    ownSearchConfig.outerConfig.formCol ? (24-ownSearchConfig.outerConfig.formCol) : 4
                "
                class="qilin-QilinSearch-button-box"
            >
                <template v-for="(item,index) in ownSearchConfig.buttonConfig">
                    <el-button
                        :key="'button-'+index"
                        round
                        :type="item.type || 'primary' "
                        :size="item.size || 'small' "
                        :class="item.className"
                        @click="item.click && item.click(item)"
                    >{{item.btnName || "按钮"}}</el-button>
                </template>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name:"QilinSearch",
    props:{
        // 搜索表单的配置项
        searchConfig:{
            type:Object
        }
    },
    data(){
        return{
            // 组件自身的默认配置
            ownSearchConfig:{
                // 外层元素的宽度排列配置
                outerConfig:{
                    formCol:20, //form元素占比宽
                    btnCol:4 //button按钮组占比宽
                },
                // el-form表单元素的相关配置
                elFormConfig:{
                    refName:"formSearch", //el-form的ref名称--默认formSearch
                    labelWidth:"6.429rem", //el-form的label宽度--默认90px
                    labelPosition:"right", //el-form的label对齐方式--默认right
                    labelSuffix:"：", //el-form的label后缀--默认为：
                    size:"small" //el-form的表单大小--默认small
                },
                // 搜索表单结构相关配置项
                itemConfig:[
                    {
                        dataName:"name", //对应后台数据名
                        labelName:"", //label文字名称
                        className:"", //el-form-item元素的自定义类名
                        type:"input", //表单输入类型--默认为input
                        dataListName:"", //拉数据名对应selectLists中属性--当type为select时生效
                        slotName:"" //slot元素name名称，仅当type为slot时生效
                    }
                ],
                // 下拉列表数据配置
                selectLists:[],
                // 表单需要提交的数据配置
                submitData:{},
                // 搜索栏右侧按钮数据配置
                buttonConfig:[
                    // {
                    //     btnName:"按钮", //按钮名称
                    //     type:"primary", //按钮类型
                    //     size:"small", //按钮大小
                    //     className:"", //按钮自定义类名
                    //     click:"event" //点击事件
                    // }
                ]
            }
        }
    },
    watch:{
        searchConfig:{
            handler(newValue,oldValue){
                this.initOwnSearchConfig(newValue);
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        // 将传来的配置赋值给组件自身
        initOwnSearchConfig(target){
            this.ownSearchConfig=this.$QilinUtils.deepCopy(target,this.ownSearchConfig);
            // for(let key in target){
            //     if(Object.keys(this.ownSearchConfig).includes(key)){
            //         this.ownSearchConfig[key]=target[key];
            //     };
            // };
        },
        // 点击搜索按钮事件监听
        searchData(){
            this.$emit("searchData");
        },
        // 点击重置搜索表单事件监听
        resetSearchData(){
            this.$refs[this.ownSearchConfig.elFormConfig.refName || "formSearch"].resetFields();
            for(let key in this.ownSearchConfig.submitData){
                this.ownSearchConfig.submitData[key]="";
            };
            this.$emit("update:searchConfig",this.$QilinUtils.deepCopy(this.ownSearchConfig,{}));
            this.searchData();
        }
    }
}
</script>
<style lang="less" scoped>
.qilin-QilinSearch{
    width:100%;
    .qilin-QilinSearch-button-box{
        text-align:right;
    }
}
</style>