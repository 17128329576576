// 引入全局组件
import QilinComponents from "./components/index.js";
console.log(QilinComponents);

const install=(Vue)=>{
    if(install.installed){ // 防止重复注册
        return;
    };
    install.installed=true;
    Object.values(QilinComponents).forEach((item)=>{
        item.install(Vue);
    });
};

if(typeof window !== "undefined" && window.Vue){
    install(window.Vue);
};

export default install;