//自定义深拷贝方法
function deepCopy(arg,target){
    for(let key in arg){
        if(!arg[key]){//如果拷贝的属性为null或undefined时
            target[key]=arg[key];
            continue;
        };
        // if(arg[key].toString()==="[object Object]"){//如果拷贝的属性为对象类型
        if(arg[key].constructor.name=="Object"){//如果拷贝的属性为对象类型--这样解决了上述深拷贝的问题
            target[key]=deepCopy(arg[key],{});
            continue;
        };
        if(Array.isArray(arg[key])){//如果拷贝的属性为数组类型
            target[key]=deepCopy(arg[key],[]);
            continue;
        };
        target[key]=arg[key];//如果拷贝的属性为基本属性类型
    };
    return target;
};

export default {
    deepCopy
}