/**
 * 元素数据挂载相关方法
 */

import QilinElement from "./element.js";
const DataName="Qilin-datas"

export default {
    /**
     * 获取某个元素上的指定数据
     * @param {Element} element 元素节点
     * @param {String} keyName 数据名
     * @returns 返回数据
     */
    getNodeData(element,keyName){
        if(!QilinElement.isElement(element) && element !== window){
            throw new TypeError("第一个参数必须是一个元素节点");
        }else{
            let data=element[DataName] || {};
            if(keyName === undefined || keyName === null || keyName === ""){
                return data;
            }else{
                return data[keyName];
            };
        };
    },
    /**
     * 在某个元素上绑定数据
     * @param {Element} element 元素节点
     * @param {String} keyName 数据名
     * @param {String} value 值
     * @returns 无返回值
     */
    setNodeData(element,keyName,value){
        if(!QilinElement.isElement(element) && element !== window){
            throw new TypeError("第一个参数必须是一个元素节点");
        }else if(keyName === undefined || keyName === null || keyName === ""){
            throw new TypeError("第二个参数必须是一个独立值");
        }else{
            let data=element[DataName] || {};
            data[keyName]=value;
            element[DataName]=data;
        };
    },
    /**
     * 判断某个元素上是否绑定指定数据
     * @param {Element} element 元素节点
     * @param {String} keyName 数据名
     * 返回布尔值
     */
    hasNodeData(element,keyName){
        if(!QilinElement.isElement(element) && element !== window){
            throw new TypeError("第一个参数必须是一个元素节点");
        }else if(keyName === undefined || keyName === null || keyName === ""){
            throw new TypeError("第二个参数必须是一个独立值");
        }else{
            let data=element[DataName] || {};
            return data.hasOwnProperty(keyName);
        };
    },
    /**
     * 判断某个元素上是否绑定指定数据
     * @param {Element} element 元素节点
     * @param {String} keyName 数据名
     * 无返回值
     */
    removeNodeData(element,keyName){
        if(!QilinElement.isElement(element) && element !== window){
            throw new TypeError("第一个参数必须是一个元素节点");
        }else{
            let data=element[DataName] || {};
            if(keyName === undefined || keyName === null || keyName === ""){
                element[DataName]={};
            }else{
                Reflect.deleteProperty(data,keyName);
                element[DataName]=data;
            };
        };
    }
};