<template>
    <div class="qilin-QilinDialog">
        <el-dialog
            :visible.sync="ownDialogConfig.isShow"
            :title="ownDialogConfig.title || '提示' "
            :top="ownDialogConfig.top"
            :close-on-click-modal="ownDialogConfig.closeOnClickModal"
            :close-on-press-escape="ownDialogConfig.closeOnPressEscape"
            :modal="ownDialogConfig.modal"
            :modal-append-to-body="ownDialogConfig.modalAppendToBody"
            :append-to-body="ownDialogConfig.appendToBody"
            :width="ownDialogConfig.width || '600px' "
            :center="ownDialogConfig.center"
            :before-close="handleClose"
        >
            <slot></slot>
            <div slot="footer">
                <template v-if="ownDialogConfig.additionalButton && ownDialogConfig.additionalButton.length > 0">
                    <el-button
                        v-for="(item,index) in ownDialogConfig.additionalButton" 
                        :key="index"
                        :type="item.btnType || 'default' "
                        :size="item.btnSize || 'small' "
                        @click="item.click && item.click()"
                    >{{item.btnText || "额外按钮"}}</el-button>
                </template>
                <el-button type="default" size="small" @click="handleClose">
                    {{ownDialogConfig.closeText || "取消"}}
                </el-button>
                <el-button type="primary" size="small" @click="$emit('submitConfirm')">
                    {{ownDialogConfig.confirmText || "确认"}}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:"QilinDialog",
    props:{
        dialogConfig:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    watch:{
        dialogConfig:{
            handler(newValue,oldValue){
                // console.log(newValue);
                this.initOwnDialogConfig(newValue);
            },
            immediate:true,
            deep:true
        }
    },
    data(){
        return {
            // 组件自身的默认配置
            ownDialogConfig:{
                isShow:false, //是否显示弹窗
                title:"提示", //弹窗标题
                top:"15vh", //margin-top值--会对拖拽造成影响
                closeOnClickModal:false, //是否可以通过点击阴影部分即modal关闭弹窗
                closeOnPressEscape:false, //是否可以通过按下ESC关闭弹窗
                modal:true, //是否需要遮罩层
                modalAppendToBody:true, //遮罩层是否插入至body元素上去
                appendToBody:true, //弹窗元素自身是否插入到body元素上去
                width:"600px", //弹窗的宽度
                center:true, //弹窗头部和底部是否水平居中
                confirmText:"确认", //确认按钮的文字信息
                closeText:"取消", //取消按钮的文字信息
                additionalButton:[ //额外按钮的相关信息配置项
                    // {
                    //     btnText:"额外按钮",
                    //     btnType:"default",
                    //     btnSize:"small", 
                    //     click:"click"
                    // }
                ]
            }
        }
    },
    methods:{
        // 将传来的配置赋值给组件自身
        initOwnDialogConfig(target){
            // this.ownDialogConfig=target;
            this.ownDialogConfig=this.$QilinUtils.deepCopy(target,this.ownDialogConfig);
        },
        // 点击关闭按钮事件监听
        handleClose(){
            this.$emit("handleClose");
        }
    },
    deactivated(){
        // console.log("被缓存的组件失活");
        this.ownDialogConfig.isShow=false;
        this.$emit("update:dialogConfig",this.$QilinUtils.deepCopy(this.ownDialogConfig,{}));
    }
}
</script>
<style lang="less" scoped>
.qilin-QilinDialog{
    position:absolute;
}
// ::v-deep .el-dialog{
//     transform:translateY(-50%);
// }
</style>