/**
 * 字符串相关操作方法
 */

import QilinNumber from "./number.js";

export default {
    /**
     * 向字符串的指定位置插入字符串
     * @param {String} oldStr 原始字符串
     * @param {String} newStr 插入的新字符串
     * @param {Number} index 插入的位置-索引 
     */
    insertString(oldStr,newStr,index){
        if(!oldStr || typeof oldStr !== "string"){
            throw new Error("第一个参数必须是一个字符串");
        };
        if(typeof newStr !== "string"){
            throw new Error("第二个参数必须是一个字符串");
        };
        if(index && !QilinNumber.isNumber(index)){
            throw new Error("第三个参数必须是一个数字");
        };
        if(index && index < 0){
            throw new Error("第三个参数必须大于等于0");
        };
        if(!index){ //若没传index或者index为null，则默认将新字符串插入到最后
            index=oldStr.length;
        };
        return oldStr.substring(0,index) + newStr + oldStr.substring(index,oldStr.length);
    },
    /**
     * 删除指定位置的字符串
     * @param {String} oldStr 原始字符串
     * @param {Number} index 删除字符串的位置起始索引
     * @param {Number} length 删除字符串的长度
     */
    deleteString(oldStr,index,length){
        if(!oldStr || typeof oldStr !== "string"){
            throw new Error("第一个参数必须是一个字符串");
        };
        if(index && !QilinNumber.isNumber(index)){
            throw new Error("第二个参数必须是一个数字");
        };
        if(index && index < 0){
            throw new Error("第二个参数必须大于等于0");
        };
        if(!index){ //若没传index或者index为null，则默认从字符串的结尾处开始删除，即不删除任何字符
            index=oldStr.length;
        };
        if(length && !QilinNumber.isNumber(length)){
            throw new Error("第三个参数必须是一个数字");
        };
        if(length && length < 0){
            throw new Error("第三个参数必须大于等于0");
        };
        if(!length){ //若没传length或者length为null，则默认删除字符串的长度为0，即不删除任何字符
            length=0;
        };
        return oldStr.substring(0,index) + oldStr.substring(index+length,oldStr.length);
    },
    /**
     * 替换指定位置的字符串
     * @param {String} oldStr 原始字符串
     * @param {Number} start 被替换字符串的起始索引位置
     * @param {Number} end 被替换字符串的结束索引位置
     * @param {String} newStr 新字符串
     */
    replaceString(oldStr,start,end,newStr){
        if(!oldStr || typeof oldStr !== "string"){
            throw new Error("第一个参数必须是一个字符串");
        };
        if(!QilinNumber.isNumber(start)){
            throw new Error("第二个参数必须是一个数字");
        };
        if(start < 0){
            throw new Error("第二个参数必须大于等于0");
        };
        if(!QilinNumber.isNumber(end)){
            throw new Error("第三个参数必须是一个数字");
        };
        if(end < 0){
            throw new Error("第三个参数必须大于等于0");
        };
        if(typeof newStr !== "string"){
            throw new Error("第一个参数必须是一个字符串");
        };
        return oldStr.substring(0,start) + newStr +oldStr.substring(end,oldStr.length);
    },
    /**
     * 去除字符串中的空格
     * @param {String} oldStr 原始字符串
     * @param {Boolean} global 为true时去除所有空格，否则只去除两边空格，默认为false
     */
    trimString(oldStr,global=false){
        if(!oldStr || typeof oldStr !== "string"){
            throw new Error("第一个参数必须是一个字符串");
        };
        if(global){
            return oldStr.replace(/\s/g,"");
        }else{
            return oldStr.replace(/(^\s+)|(\s+$)/g,"");
        };
    }
};