<template>
    <div class="qilin-QilinTable">
        <el-table 
            :data="ownTableConfig.tableData"
            :border="ownTableConfig.elTableConfig.border || true "
            :size="ownTableConfig.elTableConfig.size || 'small' "
        >
            <template v-for="(item,index) in ownTableConfig.headerConfig">
                <el-table-column 
                    v-if="item.type == 'sortIndex' "
                    :key="item.label" 
                    :label="item.label" 
                    :align="item.align || 'center' "
                    :width="item.width ? item.width : '' "
                    v-bind="item"
                >
                    <template v-slot:default="scope">
                        <span>
                            {{(ownTableConfig.paginationsObj.currentPage-1)*ownTableConfig.paginationsObj.pageSize+scope.$index+1}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column 
                    v-if="!item.type || item.type == 'text' "
                    :key="item.label" 
                    :label="item.label" 
                    :prop="item.prop" 
                    :align="item.align || 'center'" 
                    :width="item.width ? item.width : '' "
                    :show-overflow-tooltip="item.ellipsis || false "
                    v-bind="item"
                >
                </el-table-column>
                <el-table-column 
                    v-if="item.type == 'date' "
                    :key="item.label" 
                    :label="item.label" 
                    :prop="item.prop" 
                    :align="item.align || 'center'" 
                    :width="item.width ? item.width : '' "
                    :show-overflow-tooltip="item.ellipsis || false "
                    v-bind="item"
                >
                    <template v-slot:default="scope">
                        <span>{{getDate(scope.row[item.prop],item.format || "")}}</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    v-if="item.type == 'slot' "
                    :key="item.label" 
                    :label="item.label" 
                    :prop="item.prop" 
                    :align="item.align || 'center'" 
                    :width="item.width ? item.width : '' "
                    :show-overflow-tooltip="item.ellipsis || false "
                    v-bind="item"
                >
                    <template v-slot:default="scope">
                        <div class="slot-box">
                            <slot :name="item.slotName" :data="{scope,item}"></slot>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="item.type == 'operate' "
                    :key="item.label" 
                    :label="item.label"
                    :align="item.align || 'center'" 
                    :width="item.width ? item.width : '' "
                    :fixed="item.fixed || 'right' "
                    v-bind="item"
                >
                    <template v-slot:default="scope">
                        <div class="operate-box">
                            <el-button 
                                v-for="operateItem in item.operateOptions" 
                                :key="operateItem.buttonName"
                                :type="operateItem.buttonType || 'default' "
                                :size="operateItem.buttonSize || 'mini' "
                                :disabled="operateItem.disabled || (operateItem.isDisabled && operateItem.isDisabled(scope.row,item))"
                                @click="operateItem.buttonEvent && operateItem.buttonEvent(scope.row,item)"
                            >
                                {{operateItem.buttonName || "按钮"}}
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </template>
        </el-table>
        <el-pagination 
            :current-page="ownTableConfig.paginationsObj.currentPage" 
            :page-sizes="ownTableConfig.paginationsObj.pageSizes"
            :page-size="ownTableConfig.paginationsObj.pageSize" 
            :total="ownTableConfig.paginationsObj.total" 
            :layout="ownTableConfig.paginationsObj.layout"
            @current-change="handleCurrentPage" 
            @size-change="handleSizeChange" 
            background
        ></el-pagination>
    </div>
</template>
<script>
/**
 * 相关配置项例子请移步
 *      /views/fund/fundList.vue
 */
import globalUtils from "@/utils/index.js";
export default {
    name:"QilinTable",
    props:{
        tableConfig:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return {
            // 组件自身的默认配置
            ownTableConfig:{
                // el-table标签上的配置项
                elTableConfig:{
                    border:true, //是否带边框--默认true
                    size:"small" //表格大小--默认small
                },
                // 表头相关配置项
                headerConfig:[
                    {
                        label:"姓名", //表头名称
                        type:"text", //表头的类型--默认为text
                        fixed:"right", //是否固定--当且仅当type为operate时生效--默认right
                        slotName:"", //slot元素的name，当且仅当type为slot时有效
                        format:"", //时间格式化配置
                        prop:"name", //对应后台数据名称
                        align:"center", //对齐方式--默认center
                        width:60, //列宽-自适应宽度
                        ellipsis:false, //是否超出悬浮展示--默认false
                        operateOptions:[ //操作栏按钮配置项--当且仅当type为operate生效
                            {
                                buttonName:"按钮", //按钮名称
                                buttonType:"default", //按钮类型--默认default
                                buttonSize:"mini", //按钮大小--默认mini
                                buttonEvent:"event" //按钮点击事件
                            }
                        ]
                    }
                ],
                // 真实数据
                tableData:[],
                // 插槽名容器
                slotData:{},
                // 表格分页配置项
                paginationsObj:{
                    currentPage:1, //当前页
                    pageSize:5, //当前每页数量
                    pageSizes:[5,10,15], //每页显示多少条选项集
                    total:0, //表格总数量
                    layout:"total,sizes,prev,pager,next,jumper"
                }
            }
        }
    },
    computed:{
        getDate(){
            return function(date,format){
                if(!date){
                    return "-";
                };
                return globalUtils.formatToDate(date,format);
            };
        }
    },
    watch:{
        tableConfig:{
            handler(newValue,oldValue){
                // console.log(newValue);
                this.initOwnTableConfig(newValue);
            },
            immediate:true,
            deep:true
        }
    },
    methods:{
        // 将传入进来的表格配置项赋值给组件自身
        initOwnTableConfig(target){
            this.ownTableConfig=this.$QilinUtils.deepCopy(target,this.ownTableConfig);
            // for(let key in target){
            //     if(Object.keys(this.ownTableConfig).includes(key)){
            //         this.ownTableConfig[key]=target[key];
            //     };
            // };
            // console.log(this.ownTableConfig);
        },
        // 监听切换页码事件
        handleCurrentPage(page){
            // console.log(page);
            this.$emit("changeCurrentPage",page);
        },
        // 监听切换每页数量事件
        handleSizeChange(size){
            // console.log(size);
            this.$emit("changeCurrentSize",size);
        }
    }
}
</script>
<style lang="less" scoped>
.qilin-QilinTable{
    width:100%;
    >.el-pagination{
        text-align:right;
        padding:20px;
    }
}
</style>