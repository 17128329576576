import Vue from 'vue';
import Vuex from 'vuex';

import homeModule from "./home/index.js";

Vue.use(Vuex);

const types={
	set_authenticated:"set_authenticated",
	set_userInfo:"set_userInfo"
};

const state={
	isAuthenticated:false,//是否登录授权
	userInfo:{}//用户信息
};

const getters={
	isAuthenticated:(state)=>{
		return state.isAuthenticated;
	},
	userInfo:(state)=>{
		return state.userInfo;
	}
};

const mutations={
	[types.set_authenticated](state,isAuthenticated){
		state.isAuthenticated=isAuthenticated;
	},
	[types.set_userInfo](state,userInfo){
		if(userInfo){
			state.userInfo=userInfo;
		}else{
			state.userInfo={};
		};
	}
};

const actions={
	//是否有token权限
	setAuthenticated({commit},isAuthenticated){
		commit(types.set_authenticated,isAuthenticated);
	},
	//获取用户信息
	setUserInfo({commit},userInfo){
		commit(types.set_userInfo,userInfo);
	},
	//清空用户相关信息以及token权限
	clearUserInfo({commit}){
		commit(types.set_authenticated,false);
		commit(types.set_userInfo,null);
	}
};

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	modules:{
		home:homeModule
	}
});
