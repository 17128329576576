<template>
    <div class="qilin-QilinUploadImage">
        <el-upload
            ref="QilinUploadImage"
            :key="num"
            :action="ownUoloadImageConfig.action || '#' "
            :list-type="ownUoloadImageConfig.listType || 'picture-card' "
            :auto-upload="ownUoloadImageConfig.autoUpload || false "
            :limit="ownUoloadImageConfig.limit || 1 "
            :file-list="fileList "
            :multiple="ownUoloadImageConfig.multiple || false "
            :show-file-list="ownUoloadImageConfig.showFileList || false "
            :on-change="changeImage"
            :on-remove="removeImage"
            :on-exceed="exceedImage"
            :on-preview="previewImage"
        >
            <i slot="default" class="el-icon-camera-solid"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogImage.isShow" title="图片预览" :append-to-body="true" center>
            <img width="100%" height="600px" :src="dialogImage.imgSrc" alt="">
            <!-- <el-image :src="dialogImage.imgSrc" :preview-src-list="[dialogImage.imgSrc]"></el-image> -->
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:"QilinUploadImage",
    inject:["uploadImageUrl"],
    props:{
        uploadImageConfig:{
            type:Object
        },
        value:{
            type:[Array,String]
        }
    },
    data(){
        return {
            // 组件自身的默认配置
            ownUoloadImageConfig:{
                action:"#", //文件上传至服务器url
                listType:"picture-card", //文件展示类型
                autoUpload:false, //是否自动上传
                fileList:[], //已上传文件列表
                multiple:false, //是否支持多选文件
                showFileList:false, //是否显示已上传文件
                size:20*1000*1000, //限制文件上传大小
                imageType:["jpg","jpeg","png","gif"], //限制文件上传类型
            },
            // 文件上传列表
            fileList:[],
            dialogImage:{ // 选中上传图片后的图片查看弹窗
                isShow:false,
                imgSrc:""
            },
            num:0
        }
    },
    computed:{
        getImageUrl(){
            return function(fileName){
                return !this.$route.fullPath.includes("harem")?
                        this.uploadImageUrl+fileName : 
                        this.uploadImageUrl+"wifes/"+fileName;
            };
        }
    },
    watch:{
        uploadImageConfig:{
            handler(newValue,oldValue){
                this.initOwnUploadImageConfig(newValue);
            },
            immediate:true,
            deep:true
        },
        value:{
            handler(newValue,oldValue){
                // 当表单配置项中未传fileList或其为[]的，就执行这一步的，主要用来进行回显，较简单
                // console.log("====",newValue,this.fileList);
                if(!this.ownUoloadImageConfig.fileList || this.ownUoloadImageConfig.fileList.length === 0){
                    if(newValue){
                        if(this.fileList.length === 0){ //解决多次触发造成页面闪动的问题
                            this.num++;
                            this.fileList=newValue.map((item)=>{
                                return {
                                    url:this.getImageUrl(item.fileName),
                                    fileName:item.fileName //旧图片以此属性来标识
                                };
                            });
                        };
                    }else{
                        this.fileList=[];
                    };
                }else{ //若传了fileList，则默认展示传的fileList
                    if(this.fileList.length === 0){
                        this.num++;
                        this.fileList=this.$QilinUtils.deepCopy(this.ownUoloadImageConfig.fileList,[]);
                    };
                };
            },
            // immediate:true
        }
    },
    methods:{
        // 将传来的配置赋值给组件自身
        initOwnUploadImageConfig(target){
            // this.ownUoloadImageConfig=target;
            this.ownUoloadImageConfig=this.$QilinUtils.deepCopy(target,this.ownUoloadImageConfig);
            // this.fileList=target.fileList;
        },
        // 选中图片事件监听
        changeImage(file,fileList){
            // console.log(file);
            const imageType=file.name.split(".").pop();
            if(!this.ownUoloadImageConfig.imageType.includes(imageType)){
                this.$message({
                    message:"请上传图片格式的文件",
                    type:"warning"
                });
                this.$refs["QilinUploadImage"].uploadFiles.splice(0,1);
            }else if(file.size > this.ownUoloadImageConfig.size){
                this.$message({
                    message:`图片大小不可超过${this.ownUoloadImageConfig.size}k`,
                    type:"warning"
                });
                // console.log(this.$refs["QilinUploadImage"]);
                this.$refs["QilinUploadImage"].uploadFiles.splice(0,1);
            }else{
                this.fileList.push({
                    url:file.url,
                    raw:file.raw
                });
                this.$emit("success",this.fileList);
            };
        },
        // 点击移除图片事件监听
        removeImage(file,fileList){
            // console.log("移除图片",file,fileList);
            // 如果只能上传一张图片
            if(!this.ownUoloadImageConfig.limit || this.ownUoloadImageConfig.limit === 1){
                this.fileList=[];
            }else{ //若上传多张图片
                const index=this.fileList.findIndex((item)=>{return item.url === file.url});
                this.fileList.splice(index,1);
            };
            this.$emit("success",this.fileList);
        },
        // 当选择图片超出上传数量时事件监听
        exceedImage(files,fileList){
            // console.log(files,fileList);
            if(!this.ownUoloadImageConfig.limit || this.ownUoloadImageConfig.limit === 1){
                this.$message({
                    message:"注意只能上传一张图片",
                    type:"warning"
                });
            }else{
                this.$message({
                    message:`不能上传超过${this.ownUoloadImageConfig.limit}张图片`,
                    type:"warning"
                });
            };
        },
        // 当点击选中图片预览时事件监听
        previewImage(file){
            // console.log(file);
            this.dialogImage.isShow=true;
            this.dialogImage.imgSrc=file.url;
        }
    }
}
</script>
<style lang="less" scoped>
// .qilin-QilinUploadImage{
    // ::v-deep .el-upload-list__item.is-ready{
    //     display:none;
    // }
// }
</style>