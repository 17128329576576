const state={
    isCollapse:false, //是否收缩导航栏
    width:14.286 //导航栏展开宽度
};

const getters={
    getIsCollapse:(state)=>{
        return state.isCollapse;
    },
    getWidth:(state)=>{
        return state.width;
    }
};

const mutations={
    set_isCollapse(state,isCollapse){
        state.isCollapse=isCollapse;
    },
    set_width(state,width){
        state.width=width;
    }
};

export default {
    namespaced:true,
    state,
    getters,
    mutations
};