/**
 * 下拉滚动加载自定义指令
 */

let Timer=null;
export default {
    name:"loadmore",
    loadmore:(el,binding)=>{
        // console.log(el,binding);
        // 获取滚动下拉页面DOM
        let ScrollDom=el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
        // console.log(ScrollDom.scrollTop,ScrollDom.scrollHeight,ScrollDom.clientHeight);
        let ScrollPosition=0;
        ScrollDom && ScrollDom.addEventListener("scroll",function(){
            // this.scrollTop表示当前的垂直滚动距离，ScrollPosition表示上次滚动前的垂直滚动距离
            // 若ScrollDirection为true表示向下滚动，若为false表示向上滚动
            let ScrollDirection=this.scrollTop - ScrollPosition > 0 ;
            // console.log(ScrollDirection,this.scrollTop,ScrollPosition);
            // 赋值上次垂直滚动距离
            ScrollPosition=this.scrollTop;
            // 设置最小限制距离元素底部距离，默认为10，若低于10时则默认触发自定义滚动事件
            const LimitBottom=10;
            // 距离底部距离是否低于最小限制距离10--元素总高度-元素滚动距离-元素的高度
            // 若ScrollBottom为true，则表示已低于最小限制，可触发事件监听，若为false则无法触发
            let ScrollBottom=this.scrollHeight-(this.scrollTop+this.clientHeight) < LimitBottom;
            // 触发自定义滚动事件须同时具备以下两个条件
            // 1、元素滚动方向是向下滚动
            // 2、距离底部距离低于最小限制距离
            if(ScrollDirection && ScrollBottom){
                if(Timer){
                    clearTimeout(Timer);
                };
                // 添加防抖功能
                Timer=setTimeout(()=>{
                    if(binding.value){
                        binding.value();
                    };
                },50);
            };
        });
    }
};
