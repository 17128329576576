<template>
    <div class="qilin-QilinForm">
        <el-form 
            :ref="refName"
            :model="ownFormConfig.submitData"
            :rules="ownFormRules"
            :label-position="ownFormConfig.elFormConfig.labelPosition || 'right' "
            :label-width="ownFormConfig.elFormConfig.labelWidth || '4.286rem' "
            :size="ownFormConfig.elFormConfig.size || 'small' "
            :class="ownFormConfig.elFormConfig.className"
        >
            <template v-for="(item,index) in ownFormConfig.itemConfig">
                <el-form-item
                    :key="item.dataName || 'form-key-'+index "
                    :label="item.labelName || (!item.hideLabel ? '默认名称'+index : '') "
                    :prop="item.dataName"
                    :class="[
                        'width'+(item.width ? item.width : 24),
                        item.className
                    ]"
                >
                    <template v-if="item.type === 'input' || !item.type ">
                        <el-input 
                            v-model="ownFormConfig.submitData[item.dataName]"
                            :type="item.inputType || 'text' "
                            :clearable="item.clearable || true "
                            :disabled="item.disabled || false "
                            :placeholder="item.placeholder || '请输入'+item.labelName "
                            :show-password="item.showPassword || false "
                            :rows="item.rows || 2 "
                            :auto-complete="item.inputType === 'password' ? 'new-password' : 'new-text' "
                        ></el-input>
                    </template>
                    <template v-else-if="item.type === 'select' ">
                        <el-select
                            v-model="ownFormConfig.submitData[item.dataName]"
                            :clearable="item.clearable || true "
                            :disabled="item.disabled || false "
                            :multiple="item.multiple || false "
                            :collapse-tags="item.collapseTags || false"
                            :filterable="item.filterable || true "
                            v-loadmore="item.loadMore"
                            @change="(value)=>{changeFn(value,item)}"
                            @clear="()=>{item.clear && item.clear(item)}"
                        >
                            <el-option
                                v-for="optItem in ownFormConfig.selectLists[item.dataListName]"
                                :key="optItem.value"
                                :disabled="optItem.disabled || false "
                                :label="optItem.label"
                                :value="optItem.value"
                            ></el-option>
                        </el-select>
                    </template>
                    <template v-else-if="item.type === 'uploadImage' ">
                        <QilinUploadImage
                            :uploadImageConfig.sync="item"
                            v-model="ownFormConfig.submitData[item.dataName]"
                            @success="(fileList)=>{uploadImageFn(fileList,item)}"
                        ></QilinUploadImage>
                    </template>
                    <template v-else-if="item.type === 'dateSelect' ">
                        <el-date-picker
                            v-model="ownFormConfig.submitData[item.dataName]"
                            :type="item.dateType || 'date' "
                            :placeholder="item.placeholder || '请选择'+item.labelName "
                            :format="item.format || 'yyyy-MM-dd' "
                            :value-format="item.format || 'yyyy-MM-dd' "
                            placement="bottom-start"
                        ></el-date-picker>
                    </template>
                    <template v-else-if="item.type === 'slot' ">
                        <slot :name="item.slotName" :data="{item}"
                            :onFocus="()=>{focusFn(item)}"
                        ></slot>
                    </template>
                    <template v-else-if="item.type === 'checkbox' ">
                        <el-checkbox-group 
                            v-model="ownFormConfig.submitData[item.dataName]"
                        >
                            <el-checkbox 
                                v-for="ite in ownFormConfig.selectLists[item.dataListName]"
                                :key="ite.label"
                                :label="ite.label"
                                :disabled="ite.disabled || false "
                            ></el-checkbox>
                        </el-checkbox-group>
                    </template>
                </el-form-item>
            </template>
            <el-form-item class="qilin-QilinForm-button"
                v-if="ownFormConfig.buttonConfig && ownFormConfig.buttonConfig.length > 0"
            >
                <template v-for="(item,index) in ownFormConfig.buttonConfig">
                    <el-button 
                        :key="'button-'+index"
                        :type="item.type || 'primary' "
                        :size="item.size || 'small' "
                        :class="item.className"
                        @click="item.click && item.click(item.formRefName)"
                    >{{item.btnName || "按钮" }}</el-button>
                </template>
            </el-form-item>
        </el-form>
        <slot name="form-login-tip"></slot>
    </div>
</template>
<script>
import QilinFormRules from "./formRules.js";

import QilinUploadImage from "../QilinUploadImage/QilinUploadImage.vue";
let Timer=null;
export default {
    name:"QilinForm",
    props:{
        // 元素的ref别名
        refName:{
            type:String,
            default:"formData"
        },
        // 表单的相关配置项
        formConfig:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    components:{
        QilinUploadImage
    },
    data(){
        return {
            // 组件自身的默认配置
            ownFormConfig:{
                // el-form标签上的相关配置
                elFormConfig:{
                    labelPosition:"right", //label对齐方式--默认right
                    labelWidth:"4.286rem", //label宽度--默认60px
                    size:"small", //el-form的大小--默认small
                    className:"" //el-form的自定义类名
                },
                // 表单结构相关配置项
                itemConfig:[
                    {
                        dataName:"name", //对应后台数据名
                        labelName:"姓名", //label名称
                        hideLabel:false, //是否隐藏label字段
                        type:"input", //表单组件类型--默认为input
                        dateType:"date", //时间选择器类型--默认为date--当type为dateSelect时生效
                        format:"yyyy-MM-dd", //时间格式化配置--默认yyyy-MM-dd--当type为dateSelect时生效
                        dataListName:"", //下拉数据名对应selectLists中属性--当type为select时生效
                        inputType:"text", //表单input类型--默认为text--仅当type为input时生效
                        width:24, //表单item的栅栏占比宽度--默认24
                        className:"", //el-form-item的自定义类名
                        clearable:true, //是否可以一键清空--默认true
                        disabled:false, //是否禁用--默认false
                        placeholder:"", //表单提示语
                        showPassword:false, //是否显示密码--默认false--当inputType为password生效
                        rows:2, //行高--默认2--当inputType为textarea生效
                        multiple:false, //是否可以多选--默认false
                        collapseTags:false, //多选时是否将选中值按文字的形式展示--默认false--当type为select时生效
                        filterable:true, //是否可搜索--默认true--当type为select时生效
                        loadMore:"event", //下拉滚动加载事件--当type为select时生效
                        change:"event", //change事件
                        clear:"event", //clear事件
                    }
                ],
                // 下拉数据集
                selectLists:{},
                // 提交到后台的数据集
                submitData:{},
                // 表单底部操作提交按钮相关配置项
                buttonConfig:[
                    // {
                    //     btnName:"按钮", //按钮名称
                    //     type:"primary", //按钮类型
                    //     size:"small", //按钮大小
                    //     className:"", //按钮自定义类名
                    //     click:"event" //点击事件
                    // }
                ]
            },
            // 组件自身的校验规则
            ownFormRules:{}
        }
    },
    computed:{
        multipleAttr(){
            return [this.formConfig.submitData,this.formConfig.selectLists];
        }
    },
    watch:{
        formConfig:{
            handler(newValue,oldValue){
                this.initOwnFormConfig(newValue);
            },
            immediate:true,
            deep:true
        },
        // "formConfig.submitData":{
        //     handler(newValue,oldValue){
        //         console.log(22);
        //         this.initOwnFormConfig(this.formConfig);
        //     },
        //     immediate:true,
        //     deep:true
        // },
        // "formConfig.selectLists":{
        //     handler(newValue,oldValue){
        //         console.log(22);
        //         this.initOwnFormConfig(this.formConfig);
        //     },
        //     immediate:true,
        //     deep:true
        // },
        // "formConfig.itemConfig":{
        //     handler(newValue,oldValue){
        //         console.log(22);
        //         this.initOwnFormConfig(this.formConfig);
        //         // this.createFormRules();
        //     },
        //     immediate:true,
        //     deep:true 
        // }
    },
    methods:{
        // 将传入进来的表单配置项赋值给组件自身
        initOwnFormConfig(target){
            this.ownFormConfig=this.$QilinUtils.deepCopy(target,this.ownFormConfig);
            // for(let key in target){
            //     if(Object.keys(this.ownFormConfig).includes(key)){
            //         this.ownFormConfig[key]=target[key];
            //     };
            // };
        },
        // slot元素组件聚焦事件监听--用以解决slot组件数据与该组件自身数据不共通问题
        focusFn(item){
            if(Timer){
                clearTimeout(Timer);
            };
            Timer=setTimeout(()=>{
                // console.log(item);
                this.$emit("update:formConfig",this.$QilinUtils.deepCopy(this.ownFormConfig,{}));
            },10);
        },
        // 触发表单change事件监听
        changeFn(value,item){
            this.$emit("update:formConfig",this.$QilinUtils.deepCopy(this.ownFormConfig,{}));
            item.change && item.change(value,item);
        },
        // 触发上传图片事件监听
        uploadImageFn(fileList,item){
            // console.log(fileList,item);
            this.ownFormConfig.submitData[item.dataName]=fileList;
            this.$refs[this.refName].clearValidate(item.dataName);
            this.$emit("update:formConfig",this.$QilinUtils.deepCopy(this.ownFormConfig,{}));
        },
        // 调用el-form表单组件的校验事件监听
        formValidate(callback){
            this.$refs[this.refName].validate((valid)=>{
                this.$emit("update:formConfig",this.$QilinUtils.deepCopy(this.ownFormConfig,{}));
                if(callback){
                    callback(valid);
                };
            });
        },
        // 调用el-form表单组件的清空校验事件监听
        formResetFields(){
            this.$nextTick(()=>{ //防止关闭弹窗会触发表单的校验事件
                this.$refs[this.refName].resetFields();
                for(let key in this.ownFormConfig.submitData){
                    this.ownFormConfig.submitData[key]="";
                };
                this.$emit("update:formConfig",this.$QilinUtils.deepCopy(this.ownFormConfig,{}));
            });
        },
        // 调用el-form表单组件的单字段清除校验事件监听
        formClearValidate(dataName){
            this.$refs[this.refName].clearValidate(dataName);
        },
        // 创建表单的校验规则
        createFormRules(){
            let ruleObj={};
            this.ownFormConfig.itemConfig.forEach((item)=>{
                if(item.validate && item.validate.length>0){
                    ruleObj[item.dataName]=item.validate;
                };
                if(item.otherValidate){
                    if(ruleObj[item.dataName] && ruleObj[item.dataName].length>0){
                        ruleObj[item.dataName]=ruleObj[item.dataName].concat(QilinFormRules[item.otherValidate]);
                    }else{
                        ruleObj[item.dataName]=QilinFormRules[item.otherValidate];
                    };
                };
            });
            this.ownFormRules=ruleObj;
        }
    },
    created(){
        this.createFormRules();
    }
}
</script>
<style lang="less" scoped>
.qilin-QilinForm{
    width:100%;
    >.el-form{
        >.el-form-item{
            display:inline-block;
        }
        >.width24{
            width:100%;
        }
        >.width18{
            width:75%;
        }
        >.width16{
            width:66.66%;
        }
        >.width12{
            width:50%;
        }
        >.width8{
            width:33.33%;
        }
        >.width6{
            width:25%;
        }
        >.el-form-item.qilin-QilinForm-button{
            width:100%;
            ::v-deep >.el-form-item__content{
                margin-left:0!important;
                text-align:center;
            }
        }
        .el-select{
            width:100%;
        }
    }
}
</style>