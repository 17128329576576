/**
 * iconfont图标库设置
 *      图标库会在开启多个本地服务器时会报跨域的错
 */

const IconfontVersion=["3645658_51qbxrfqp5g"]; //每次生成在线cdn链接，只需更新此处key值即可
const IconfontUrlCss="//at.alicdn.com/t/c/font_$key.css";
const loadStyle=(url)=>{
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(link);
};


export {
    IconfontVersion,
    IconfontUrlCss,
    loadStyle
};