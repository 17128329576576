// 引入全局组件
import QilinComponents from "./components/index.js";

// 引入全局方法
import QilinUtils from "./utils/index.js";

// 引入全局自定义指令
import QilinDirectives from "./directives/index.js";

// 引入Qilin工具库
import Qilin from "./QilinUtils/index.js";

// 引入全局方法扩展工具库
import QilinExtend from "./api/index.js";

// console.log(QilinExtend);

const install=(Vue)=>{
    if(install.installed){ // 防止重复注册
        return;
    };
    install.installed=true;
    Vue.prototype.$QilinUtils=QilinUtils;
    Vue.prototype.$Qilin=Qilin;
    Object.values(QilinComponents).forEach((item)=>{
        item.install(Vue);
    });
    Object.values(QilinDirectives).forEach((item)=>{
        item.install(Vue);
    });
    Object.values(QilinExtend).forEach((item)=>{
        item.install(Vue);
    });
};

if(typeof window !== "undefined" && window.Vue){
    install(window.Vue);
};

export default install;