/**
 * 该文件用以向外提供各种封装好的方法
 */

//将UTC时间格式转换为YYYY-MM-DD hh:mm:ss等格式
const formatToDate=(date,type)=>{
    let MyDate=new Date(date);
    let Year=MyDate.getFullYear(); //获取年
    let Month=(MyDate.getMonth()+1)<10?"0"+(MyDate.getMonth()+1):(MyDate.getMonth()+1); //获取月
    let Day=MyDate.getDate()<10?"0"+MyDate.getDate():MyDate.getDate(); //获取日
    let Hour=MyDate.getHours()<10?"0"+MyDate.getHours():MyDate.getHours(); //获取小时
    let Minute=MyDate.getMinutes()<10?"0"+MyDate.getMinutes():MyDate.getMinutes(); //获取分钟
    let Second=MyDate.getSeconds()<10?"0"+MyDate.getSeconds():MyDate.getSeconds(); //获取秒钟
    // if(!type){//默认返回YYYY-MM-DD hh:mm:ss格式
    //     return Year+"-"+Month+"-"+Day+" "+Hour+":"+Minute+":"+Second;
    // }
    let formatDate=Year+"-"+Month+"-"+Day+" "+Hour+":"+Minute+":"+Second;
    switch (type) {
        case "yyyy" : formatDate=Year+"" ; break ;
        case "yyyy-MM" : formatDate=Year+"-"+Month ; break;
        case "yyyy-MM-dd" : formatDate=Year+"-"+Month+"-"+Day ; break;
        case "yyyy-MM-dd hh:mm" : formatDate=Year+"-"+Month+"-"+Day+" "+Hour+":"+Minute ; break;
        case "yyyy-MM-dd hh:mm:ss" : formatDate=Year+"-"+Month+"-"+Day+" "+Hour+":"+Minute+":"+Second ; break;
        case "MM" : formatDate=Month+"" ; break;
        case "MM-dd" : formatDate=Month+"-"+Day ; break;
        case "MM-dd hh:mm" : formatDate=Month+"-"+Day+" "+Hour+":"+Minute ; break;
        case "MM-dd hh:mm:ss" : formatDate=Month+"-"+Day+" "+Hour+":"+Minute+":"+Second ; break;
        case "dd" : formatDate=Day+"" ; break;
        case "dd hh:mm" : formatDate=Day+" "+Hour+":"+Minute ; break;
        case "dd hh:mm:ss" : formatDate=Day+" "+Hour+":"+Minute+":"+Second ; break;
    };
    return formatDate;
};

export default {
    formatToDate
}