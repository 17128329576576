/**
 * 日期相关操作方法
 */

import QilinNumber from "./number.js";

export default {
    /**
     * 获取前N个月的日期，包含本月
     * @param {Date} date 指定日期，默认为今日
     * @param {Number} num 指定月数，默认为1
     */
    getPrevMonths(date,num){
        if(!date || new Date(date) == "Invalid Date"){
            date=new Date();
        }else{
            date=new Date(date);
        };
        if(!num || !QilinNumber.isNumber(num)){
            num=1;
        };
        let dateArr=[date];
        for(let i=0;i<num-1;i++){
            let Year=date.getFullYear();
            let Month=date.getMonth();
            if(Month === 0){
                Month=12;
                Year--;
            };
            let d=new Date();
            d.setMonth(Month-1);
            d.setFullYear(Year);
            dateArr.push(d);
            date=d;
        };
        return dateArr;
    },
    /**
     * 获取后N个月后的日期，包含本月
     * @param {Date} date 指定日期，默认为今日
     * @param {Number} num 指定月数，默认为1
     */
    getNextMonths(date,num){
        if(!date || new Date(date) == "Invalid Date"){
            date=new Date();
        }else{
            date=new Date(date);
        };
        if(!num || !QilinNumber.isNumber(num)){
            num=1;
        };
        let dateArr=[date];
        for(let i=0;i<num-1;i++){
            let Year=date.getFullYear();
            let Month=date.getMonth();
            if(Month === 11){
                Month = -1;
                Year++;
            };
            let d=new Date();
            d.setMonth(Month+1);
            d.setFullYear(Year);
            dateArr.push(d);
            date=d;
        };
        return dateArr;
    },
    /**
     * 获取指定天数后的日期
     * @param {Date} date 指定日期，默认为今日
     * @param {Number} num 指定天数，默认为0
     */
    getDateAfterDays(date,num){
        if(!date || new Date(date) == "Invalid Date"){
            date=new Date();
        }else{
            date=new Date(date);
        };
        if(!num || !QilinNumber.isNumber(num)){
            num=0;
        };
        return new Date(date.getTime() + num*24*60*60*1000);
    },
    /**
     * 获取指定天数前的日期
     * @param {Date} date 指定日期，默认为今日
     * @param {Number} num 指定天数，默认为0
     */
     getDateBeforeDays(date,num){
        if(!date || new Date(date) == "Invalid Date"){
            date=new Date();
        }else{
            date=new Date(date);
        };
        if(!num || !QilinNumber.isNumber(num)){
            num=0;
        };
        return new Date(date.getTime() - num*24*60*60*1000);
    },
    /**
     * 获取某年的某个月的总天数
     * @param {Number} year
     * @param {Number} month
     */
    getDays(year,month){
        if(!QilinNumber.isNumber(year)){
            throw new TypeError("第一个参数为年份，必须是数字类型");
        };
        if(!QilinNumber.isNumber(month)){
            throw new TypeError("第二个参数为月份，必须是数字类型");
        };
        year=parseInt(year);
        month=parseInt(month);
        let d=new Date(year,month,0);
        return d.getDate();
    },
    /**
     * 判断传入参数日期的大小
     * @params {Date} date 开始日期
     * @params {Date} date 结束日期
     * @returns 返回true表示结束日期大于开始日期，false表示结束日期小于等于开始日期
     */
    compareDate(startDate,endDate){
        if(!startDate || new Date(startDate) == "Invalid Date"){
            throw new TypeError("第一个参数必传且为日期形式");
        }else if(!endDate || new Date(endDate) == "Invalid Date"){
            throw new TypeError("第二个参数必传且为日期形式");
        }else{
            return new Date(startDate).getTime() < new Date(endDate).getTime();
        };
    },
    /**
     * 获取指定格式的日期
     * @param {Date} date 日期，不传默认当前日期
     * @param {String} type 格式类型，不传默认为yyyy-MM-dd hh:mm:ss格式
     * @returns 返回指定格式的日期
     */
    formatDate(date,type){
        if(!date || new Date(date) == "Invalid Date"){
            date=new Date();
        }else{
            date=new Date(date);
        };
        let MyDate=date;
        let Year=MyDate.getFullYear(); //获取年
        let Month=(MyDate.getMonth()+1)<10?"0"+(MyDate.getMonth()+1):(MyDate.getMonth()+1); //获取月
        let Day=MyDate.getDate()<10?"0"+MyDate.getDate():MyDate.getDate(); //获取日
        let Hour=MyDate.getHours()<10?"0"+MyDate.getHours():MyDate.getHours(); //获取小时
        let Minute=MyDate.getMinutes()<10?"0"+MyDate.getMinutes():MyDate.getMinutes(); //获取分钟
        let Second=MyDate.getSeconds()<10?"0"+MyDate.getSeconds():MyDate.getSeconds(); //获取秒钟
        let formatDate=Year+"-"+Month+"-"+Day+" "+Hour+":"+Minute+":"+Second;
        switch (type) {
            case "yyyy" : formatDate=Year+"" ; break ;
            case "yyyy-MM" : formatDate=Year+"-"+Month ; break;
            case "yyyy-MM-dd" : formatDate=Year+"-"+Month+"-"+Day ; break;
            case "yyyy-MM-dd hh:mm" : formatDate=Year+"-"+Month+"-"+Day+" "+Hour+":"+Minute ; break;
            case "yyyy-MM-dd hh:mm:ss" : formatDate=Year+"-"+Month+"-"+Day+" "+Hour+":"+Minute+":"+Second ; break;
            case "MM" : formatDate=Month+"" ; break;
            case "MM-dd" : formatDate=Month+"-"+Day ; break;
            case "MM-dd hh:mm" : formatDate=Month+"-"+Day+" "+Hour+":"+Minute ; break;
            case "MM-dd hh:mm:ss" : formatDate=Month+"-"+Day+" "+Hour+":"+Minute+":"+Second ; break;
            case "dd" : formatDate=Day+"" ; break;
            case "dd hh:mm" : formatDate=Day+" "+Hour+":"+Minute ; break;
            case "dd hh:mm:ss" : formatDate=Day+" "+Hour+":"+Minute+":"+Second ; break;
            case "chinese" : formatDate=Year+"年"+Month+"月"+Day+"日" ; break;
            case "chinese hh:mm" : formatDate=Year+"年"+Month+"月"+Day+"日"+" "+Hour+":"+Minute ; break;
            case "chinese hh:mm:ss" : formatDate=Year+"年"+Month+"月"+Day+"日"+" "+Hour+":"+Minute+":"+Second ; break;
        };
        return formatDate;
    }
};