/**
 * 常用的一些js方法
 */

import QilinElement from "./element.js";

export default {
    /**
     * 判断某个字符串是否是某种类型
     * @param {String} text 传入的字符串
     * @param {String} type 字符串类型
     * @returns 返回布尔值
     */
    judgeRegText(text,type){
        if(!text || typeof text !== "string"){
            throw new TypeError("第一个参数必传且须为字符串");
        }else if(!type || typeof type !== "string"){
            throw new TypeError("第二个参数必传且须为字符串");
        }else{
            let reg=null;
            if(type === "Chinese"){ //判断text是否为中文
                reg=/^[\u4e00-\u9fa5]+$/;
            }else if(type === "chinese"){ //判断text是否含有中文
                reg=/[\u4e00-\u9fa5]/;
            }else if(type === "email"){ //判断text是否为邮箱地址
                reg=/^([A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            }else if(type === "userName"){ //判断text是否为6-16位的用户名（包含字母数字下划线）
                reg=/^[A-Za-z0-9_]{6,16}$/;
            }else if(type === "int"){ //判断text是否为整数
                reg=/^-?\d+$/;
            }else if(type === "int+"){ //判断text是否为正整数
                reg=/^\d+$/;
            }else if(type === "int-"){ //判断text是否为负整数
                reg=/^-\d+$/;
            }else if(type === "pos"){ //判断text是否为正数
                reg=/^(\d*|\d+\.)\d+$/;
            }else if(type === "neg"){ //判断text是否为负数
                reg=/^-(\d*|\d+\.)\d+$/;
            }else if(type === "number"){ //判断text是否为数字
                reg=/^-?(\d*|\d+\.)\d+$/;
            }else if(type === "phone"){ //判断text是否为手机号
                reg=/^1\d{10}$/;
            }else if(type === "idCard"){ //判断text是否为身份证号
                reg=/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
            }else if(type === "url"){ //判断text是否为url网址
                reg=/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
            }else if(type === "IPv4"){ //判断text是否为ip地址
                reg=/^(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}$/;
            }else if(type === "hex"){ //判断text是否为16进制的颜色
                reg=/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
            }else if(type === "date"){ //判断text是否为日期（.-/年月日）
                let reg1=/^((\d{4})(\.)(\d{2})(\.)(\d{2}))$/;
                let reg2=/^((\d{4})(-)(\d{2})(-)(\d{2}))$/;
                let reg3=/^((\d{4})(\/)(\d{2})(\/)(\d{2}))$/;
                let reg4=/^((\d{4})(年)(\d{2})(月)(\d{2})(日))$/;
                return (
                    reg1.test(text) || reg2.test(text) || reg3.test(text) || reg4.test(text)
                );
            }else if(type === "time"){ //判断text是否为时间
                reg=/^(20|21|22|23|[0-1]\d):[0-5]\d:[0-5]\d$/;
            }else if(type === "QQ"){ //判断text是否为QQ号
                reg=/^[1-9][0-9]{4,10}$/;
            }else if(type === "Wechat"){ //判断text是否为微信号（以字母开头，字母数字减号下划线，6至20位）
                reg=/^[a-zA-Z]([a-zA-Z0-9_-]{5,19})+$/;
            }else if(type === "licensePlate"){ //判断text是否为车牌号码
                reg =/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
            };
            if(!reg){
                throw new TypeError("第二个参数所传类型不在查询范围内");
            };
            return reg.test(text);
        };
    },
    /**
     * 判断参数是否是对象
     * @param {Object} object 
     * @returns 返回布尔值
     */
    isObject(object){
        if(Object.prototype.toString.call(object) === "[object Object]" && object){
            return true;
        }else{
            return false;
        };
    },
    /**
     * 判断参数是否是空对象
     * @param {Object} object
     * @returns 返回布尔值
     */
    isEmptyObject(object){
        if(this.isObject(object)){
            if(Object.keys(object).length === 0){
                return true;
            }else{
                return false;
            };
        }else{
            return false;
        };
    },
    /**
     * 判断参数是否为空
     * @param {*} value 
     * @returns 返回布尔值
     */
    isEmpty(value){
        return value === undefined || 
            value === null || 
            (typeof value === "object" && Object.keys(value).length === 0) || 
            (typeof value === "string" && value.trim().length === 0);
    },
    /**
     * 根据地址栏url的参数名获取其参数值
     * @param {String} key 参数名 
     * @param {String} url 指定url地址，不传默认查当前地址栏中url 
     * @returns 返回参数值
     */
    getUrlParamsValue(key,url){
        if(!key || typeof key !== "string"){
            throw new TypeError("第一个参数必传且须为字符串");
        };
        let searchUrl=window.location.search.slice(1);
        if(url){
            searchUrl=url.slice(url.indexOf("?")+1);
        };
        let reg=new RegExp("(^|&)"+key+"=([^&]*)(&|$)");
        if(!searchUrl){
            let arr=window.location.hash.split("?");
            if(url){
                arr=url.split("?");
            };
            if(arr.length === 2){
                searchUrl=arr[1];
            };
        };
        let result=searchUrl.match(reg);
        if(result){
            return decodeURIComponent(result[2]);
        }else{
            return null;
        };
    },
    /**
     * 复制指定文本信息-旧
     * @param {String} text 文本
     * @returns 无返回值 
     */
    pasteText(text){
        if(!text || typeof text !== "string"){
            throw new TypeError("所传参数必传且须为字符串");
        }else{
            let element=QilinElement.stringToDom(`<span>${text}</span>`);
            document.body.appendChild(element);
            const range=document.createRange();
            range.selectNode(element);
            const selection=window.getSelection();
            if(selection.rangeCount > 0){
                selection.removeAllRanges();
            };
            selection.addRange(range);
            document.execCommand("copy");
            document.body.removeChild(element);
        };
    },
    /**
     * 复制指定文本信息-新
     * @param {String} text 文本
     * @returns 返回Promise对象 
     */
    newPasteText(text){
        if(!text || typeof text !== "string"){
            throw new TypeError("所传参数必传且须为字符串");
        }else{
            if(window.navigator.clipboard && isSecureContext){
                return window.navigator.clipboard.writeText(text);
            }else{
                throw new TypeError("因为浏览器禁用非安全域原因该方法无法使用，请用Qilin.Common.pasteText()替代");
            };
        };
    },
    /**
     * 对某个数据进行深拷贝操作
     * @param {Object} sourceObj 源数据 
     * @param {Object} targetObj 最终返回的拷贝数据
     * @returns 返回拷贝数据
     */
    deepCopy(sourceObj,targetObj){
        for(let key in sourceObj){
            if(!sourceObj[key]){//如果拷贝的属性为null或undefined时
                targetObj[key]=sourceObj[key];
                continue;
            };
            if(sourceObj[key].constructor.name=="Object"){//如果拷贝的属性为对象类型--这样解决了上述深拷贝的问题
                targetObj[key]=this.deepCopy(sourceObj[key],{});
                continue;
            };
            if(Array.isArray(sourceObj[key])){//如果拷贝的属性为数组类型
                targetObj[key]=this.deepCopy(sourceObj[key],[]);
                continue;
            };
            targetObj[key]=sourceObj[key];//如果拷贝的属性为基本属性类型
        };
        return targetObj;
    },
    /**
     * 获取url地址栏?后面的全部键值对信息，以数组对象形式返回
     * @returns Object
     */
    getUrlSearchParamsKeyValue(){
        let searchParams=location.search || window.location.search;
        searchParams=searchParams.slice(1); //取除问号之后的数据
        let keyArr=[],valueArr=[];
        let equalArr=searchParams.match(/=/g) || [];
        let index=-1; //开始检索的位置索引
        for(let i=0;i<equalArr.length;i++){
            let startIndex=searchParams.indexOf("=",index);
            let key=searchParams.substring(index,startIndex);
            let endIndex=searchParams.indexOf("&",index);
            if(endIndex === -1){
                endIndex=searchParams.length;
            };
            let value=searchParams.substring(startIndex+1,endIndex);
            keyArr.push(key);
            valueArr.push(value);
            index=endIndex+1;
        };
        return {
            key:keyArr,
            value:valueArr
        };
    },
    /**
     * 根据key值获取url地址栏?后面的相对应的值信息
     * @param {String} keyName 键名
     * @returns value || null
     */
    getUrlSearchParamsValue(keyName){
        let searchParams=location.search || window.location.search;
        searchParams=searchParams.slice(1); //取除问号之后的数据
        let startIndex=this.getUrlSearchParamsKeyValue().key.findIndex((item)=>{
            return item === keyName;
        });
        if(startIndex !== -1){
            let startValueIndex=startIndex+(keyName.length+1);
            let endValueIndex=searchParams.indexOf("&",startValueIndex);
            if(endValueIndex === -1){
                endValueIndex=searchParams.length;
            };
            return searchParams.substring(startValueIndex,endValueIndex);
        }else {
            return null;
        };
    }
};