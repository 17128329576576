<template>
    <div class="qilin-loading">
        <div class="qilin-loading-box" :style="{backgroundColor:background}">
            <i class="iconfont icon-jiazai" v-if="!customIcon"></i>
            <i v-else :class="['iconfont',customIcon]"></i>
            <p class="qilin-loading-box-text">{{text}}</p>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        text:{
            type:String,
            default:"请求加载中..."
        },
        customIcon:String,
        background:String
    },
    name:"QilinLoading"
}
</script>
<style lang="less" scoped>
.qilin-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(255,255,255,.8);
    z-index:3000;
    > .qilin-loading-box {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        > i {
            .EDGQilinWH(@rem50,@rem50);
            font-size: @rem40;
            line-height:@rem50;
            animation: loading-rotate 2s linear infinite;
            color:@color-text-info;
        }
        > .qilin-loading-box-text {
            color: @color-text-title;
            font-size: @size-text-default;
            margin-top:@mp-small;
        }
    }
}
@keyframes loading-rotate {
    100% {
        transform: rotate(360deg);
    }
}
</style>