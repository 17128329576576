<template>
    <i :class="['qilin-icon','qilin-icon-'+name]" :style="iconStyle"></i>
</template>
<script>
export default {
    name:"qilin-icon",
    props:{
        // 图标名--简洁版
        name:{
            type:String
        }
    },
    computed: {
        iconStyle() {
            return {};
        },
    },
};
</script>
<style lang="less" scoped>
@import "../../css/qilin-icon.less";

</style>