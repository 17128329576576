/**
 * 一款轻量级的js工具库，Qilin出品，必属精品！
 */

import Number from "./number.js";
import String from "./string.js";
import Date from "./date.js";
import Platform from "./platform.js";
import Storage from "./storage.js";
import File from "./file.js";
import Element from "./element.js";
import Data from "./data.js";
import Common from "./common.js";
import Event from "./event.js";

export default {
    Number,
    String,
    Date,
    Platform,
    Storage,
    File,
    Element,
    Data,
    Common,
    Event
};
