/**
 * js中关于localStorage、sessionStorage、cookie、session等的封装操作方法
 */

export default {
    /**
     * 获取当前cookie中的所有键值对信息，并以数组对象形式返回
     * @returns Object
     */
    getKeyValueCookie(){
        const Cookies=document.cookie;
        let keyArr=[],valueArr=[];
        let index=-1;
        let equalArr=Cookies.match(/=/g) || "";
        for(let i=0;i<equalArr.length;i++){
            let startIndex=Cookies.indexOf("=",index);
            let key=Cookies.substring(index,startIndex);
            let endIndex=Cookies.indexOf(";",index);
            if(endIndex === -1){
                endIndex=Cookies.length;
            };
            let value=Cookies.substring(startIndex+1,endIndex);
            keyArr.push(key);
            valueArr.push(value);
            index=endIndex+1;
        };
        return {
            key:keyArr,
            value:valueArr
        };
    },
    /**
     * 获取当前cookie中指定key值的value，若有则返回该value，无则返回null
     * @param {String} keyName 
     * @param {Number} length length表示分隔符长度，默认分隔符为=，所以长度为1
     * @returns value || null
     */
    getValueCookie(keyName,length=1){
        const Cookies=document.cookie;
        const startIndex=this.getKeyValueCookie().key.findIndex((item)=>{
            return item.trim() === keyName;
        });
        if(startIndex !== -1){
            let startValueIndex=Cookies.indexOf(keyName)+(keyName.length+length);
            let endIndex=Cookies.indexOf(";",startValueIndex);
            if(endIndex === -1){
                endIndex=Cookies.length;
            };
            let value=decodeURI(Cookies.substring(startValueIndex,endIndex));
            return value;
        }else{
            return null;
        };
    },
    /**
     * 获取当前localStorage中指定key值的value，若有则返回value，无则返回null
     * 不传参数则查询所有localStorage值
     * @param {String} keyName
     * @returns value
     */
    getValueLocalStorage(keyName){
        if(!!keyName){
            return localStorage.getItem(keyName) || null;
        }else{
            return localStorage;
        };
    },
    /**
     * 设置当前localStorage中的key-value值，无返回值
     * @param {String} keyName 
     * @param {*} value 
     */
    setKeyValueLocalStorage(keyName,value){
        localStorage.setItem(keyName,value);
    },
    /**
     * 删除指定的localStorage或者删除所有Storage，无返回值
     * @param {String} keyName 
     * @param {Number} clearType 
     */
    removeKeyLocalStorage(keyName,clearType=0){
        if(!keyName || (clearType && clearType === 1)){ //移除所有localStorage
            localStorage.clear();
        }else{
            localStorage.removeItem(keyName);
        };
    },
    /**
     * 获取当前sessionStorage中指定key值的value，若有则返回value，无则返回null
     * 不传参数则查询所有sessionStorage值
     * @param {String} keyName
     * @returns value
     */
    getValueSessionStorage(keyName){
        if(!!keyName){
            return sessionStorage.getItem(keyName) || null;
        }else{
            return sessionStorage;
        };
    },
    /**
     * 设置当前sessionStorage中的key-value值，无返回值
     * @param {String} keyName 
     * @param {*} value 
     */
    setKeyValueSessionStorage(keyName,value){
        sessionStorage.setItem(keyName,value);
    },
    /**
     * 删除指定的sessionStorage或者删除所有Storage，无返回值
     * @param {String} keyName 
     * @param {Number} clearType 
     */
    removeKeySessionStorage(keyName,clearType=0){
        if(!keyName || (clearType && clearType === 1)){ //移除所有sessionStorage
            sessionStorage.clear();
        }else{
            sessionStorage.removeItem(keyName);
        };
    }
};