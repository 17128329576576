/**
 * 数字相关js方法
 */

export default {
    /**
     * 判断是否是数字
     * @param {Number} num
     * 返回true表示传入参数为数字，返回false则表示传入参数并非数字
     */
    isNumber(num){
        if(typeof num === "number" && !isNaN(num)){
            return true;
        }else{
            return false;
        };
    },
    /**
     * 判断是否是小数
     * @param {number} num
     * 返回true则表示传入数字为小数，返回false则不是
     */
    isDecimal(num){
        if(this.isNumber(num)){
            if(parseInt(num) < parseFloat(num)){
                return true;
            }else{
                return false;
            };
        }else{
            return false;
        };
    },
    /**
     * 判断数字是否为奇数
     * @param {number} num 
     */
    isOdd(num){
        if(!this.isDecimal(num)){
            if(num % 2 === 1){
                return true;
            }else{
                return false;
            };
        }else{
            return false;
        };
    },
    /**
     * 判断数字是否为偶数
     * @param {number} num 
     */
     isEven(num){
        if(!this.isDecimal(num)){
            if(num % 2 === 0){
                return true;
            }else{
                return false;
            };
        }else{
            return false;
        };
    },
    /**
     * 数字格式化
     * @param {Number} num
     * 对传入的数字进行每隔三位就以,隔开展示，仅对整数有效
     */
    formatNumber(num){
        if(this.isNumber(num)){
            if(!this.isDecimal(num)){
                return num.toString().replace(/(\d)(?=(?:\d{3})+$)/g,"$1,");
            }else{
                return num;
            };
        }else{
            return num;
        };
    },
    /**
     * 多个数字的加法运算
     * @param {...Number} numbers
     * 对两个数字或多个数字进行加法运算，若不传参数则返回false
     */
    addNumber(...numbers){
        return numbers.length>0 && numbers.reduce((prev,curr)=>{
            let r1=0,r2=0,m=0;
            try{
                r1=prev.toString().split(".")[1].length;
            }catch(e){};
            try{
                r2=curr.toString().split(".")[1].length;
            }catch(e){};
            m=Math.pow(10,Math.max(r1,r2));
            // prev*m  curr*m皆为将小数化整
            return (prev*m + curr*m) / m;
        });
    },
    /**
     * 多个数字的减法运算
     * @param {...Number} numbers
     * 对两个数字或多个数字进行减法运算，若不传参数则返回false
     */
    minusNumber(...numbers){
        return numbers.length>0 && numbers.reduce((prev,curr)=>{
            let r1=0,r2=0,m=0;
            try{
                r1=prev.toString().split(".")[1].length;
            }catch(e){};
            try{
                r2=curr.toString().split(".")[1].length;
            }catch(e){};
            m=Math.pow(10,Math.max(r1,r2));
            // prev*m  curr*m皆为将小数化整
            return (prev*m - curr*m) / m;
        });
    },
    /**
     * 多个数字的乘法运算
     * @param {...Number} numbers
     * 对两个数字或多个数字进行乘法运算，若不传参数则返回false
     */
    multiplyNumber(...numbers){
        return numbers.length>0 && numbers.reduce((prev,curr)=>{
            let m=0;
            let s1=prev.toString();
            let s2=curr.toString();
            try{
                m+=s1.split(".")[1].length;
            }catch(e){};
            try{
                m+=s2.split(".")[1].length;
            }catch(e){};
            return Number(s1.replace(".","")) * Number(s2.replace(".","")) / Math.pow(10,m);
        });
    },
    /**
     * 多个数字的除法运算
     * @param {...Number} numbers
     * 对两个数字或多个数字进行除法运算，若不传参数则返回false
     */
    divideNumber(...numbers){
        return numbers.length>0 && numbers.reduce((prev,curr)=>{
            let t1=0,t2=0;
            let s1=prev.toString();
            let s2=curr.toString();
            try{
                t1=s1.split(".")[1].length;
            }catch(e){};
            try{
                t2=s2.split(".")[1].length;
            }catch(e){};
            s1=Number(s1.replace(".",""));
            s2=Number(s2.replace(".",""));
            return (s1 / s2) * Math.pow(10,t2-t1);
        });
    }
};