import Vue from 'vue'
import App from './App.vue'

// 引入element ui框架
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 引入iview ui框架
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
Vue.use(ViewUI);

// 引入iconfont图标库
import {
	IconfontUrlCss,
	IconfontVersion,
	loadStyle
} from '@/assets/fonts/config.js';
IconfontVersion.forEach((item)=>{
    loadStyle(IconfontUrlCss.replace("$key",item));
});
// import "@/assets/fonts/iconfont.css";

// 引入EDGQiLin 全局组件和方法
import Qilin from "./global/index.js";
Vue.use(Qilin);

// 引入全局api请求方法
import axios from "./api/http.js";
Vue.prototype.$axios = axios;

// 引入全局公用样式
import "./assets/less/common.less";

// 引入打印插件
import Print from "vue-print-nb";
Vue.use(Print);

// 引入电子签名插件
import vueEsign from "vue-esign";
Vue.use(vueEsign);

// 引入qilin-web组件库
import QilinWeb from "./qilin-web/index.js";
Vue.use(QilinWeb);

// 引入v-viewer依插件
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(VueViewer);


import router from './router'
import store from './store'

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
