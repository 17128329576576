import Vue from 'vue'
import VueRouter from 'vue-router'
import Qilin from "qilin-utils"


Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/index"
    },
    {
        path: "/login",
        name:"login",
        component: () =>import ("@/views/register/login.vue")
    },
    {
        path: "/forget",
        name:"forget",
        component: () =>import ("@/views/register/forget.vue"),
        meta:{
            title:"修改密码"
        }
    },
    {
        path:"/index",
        component:()=>import("@/views/home/index.vue"),
        children:[
            {
                path:"",
                component:()=>import("@/views/home/home.vue"),
                meta:{
                    title:"首页"
                }
            },
            {
                path:"/fund",
                component:()=>import("@/views/fund/index.vue"),
                children:[
                    {
                        path:"fundList",
                        component:()=>import("@/views/fund/fundList.vue"),
                        meta:{
                            title:"资金流水"
                        }
                    },
                    {
                        path:"crab",
                        component:()=>import("@/views/fund/crab/index.vue"),
                        meta:{
                            title:"螃蟹市场"
                        }
                    }
                ]
            },
            {
                // path:"message",//该路径写法表示地址栏以/index/message执行，此时对应index的引入文件路径须以绝对路径引入，否则报错
                path:"/info", //该路径写法表示地址栏以/info/message搜索页面
                component:()=>import("@/views/message/index.vue"),
                children:[
                    {
                        path:"message",
                        component:()=>import("@/views/message/message/index.vue"),
                        meta:{
                            title:"个人信息"
                        }
                    },
                    {
                        path:"user",
                        component:()=>import("@/views/message/user/index.vue"),
                        meta:{
                            title:"用户管理"
                        }
                    },
                    {
                        path:"image",
                        component:()=>import("@/views/message/image/index.vue"),
                        meta:{
                            title:"图片管理"
                        }
                    }
                ]
            },
            {
                path:"/study",
                component:()=>import("@/views/study/index.vue"),
                children:[
                    {
                        path:"QilinUtils",
                        component:()=>import("@/views/study/QilinUtils/index.vue"),
                        meta:{
                            title:"QilinUtilsJS工具库"
                        }
                    },
                    {
                        path:"knowledge",
                        component:()=>import("@/views/study/knowledge/index.vue"),
                        meta:{
                            title:"Vue知识库"
                        }
                    },
                    {
                        path:"skill",
                        component:()=>import("@/views/study/skill/index.vue"),
                        meta:{
                            title:"项目技能库"
                        }
                    },
                    {
                        path:"element",
                        component:()=>import("@/views/study/elementUI/index.vue"),
                        meta:{
                            title:"elementUI示例库"
                        }
                    },
                    {
                        path:"animation",
                        component:()=>import("@/views/study/animation/index.vue"),
                        meta:{
                            title:"css/js动画库"
                        }
                    },
                    {
                        path:"QilinWeb",
                        component:()=>import("@/views/study/QilinWeb/index.vue"),
                        meta:{
                            title:"QilinWeb框架"
                        }
                    },
                    {
                        path:"echarts",
                        component:()=>import("@/views/study/echarts/index.vue"),
                        meta:{
                            title:"echarts图表库"
                        }
                    }
                ]
            },
            {
                path:"/logs",
                component:()=>import("@/views/logs/index.vue"),
                meta:{
                    title:"版本日志"
                }
            },
            {
                path:"/messageBorad",
                component:()=>import("@/views/messageBorad/index.vue"),
                meta:{
                    title:"留言板"
                }
            },
            {
                path:"/harem",
                component:()=>import("@/views/harem/index.vue"),
                children:[
                    {
                        path:"beauty",
                        component:()=>import("@/views/harem/beauty/index.vue"),
                        meta:{
                            title:"莺惭燕妨"
                        }
                    }
                ]
            }
        ]
    },
    {
        path:"*",
        component:()=>import("@/views/register/404.vue")
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

//路由守卫
router.beforeEach((to,from,next)=>{
    console.log(Qilin.Common)
    if(Vue.prototype.$Qilin.Common.getUrlParamsValue("qilinToken",location.search)){
        localStorage.setItem("qilinToken",Vue.prototype.$Qilin.Common.getUrlParamsValue("qilinToken",location.search));
    };
    const isLogin=localStorage.qilinToken?true:false;
    if(to && to.path === "/" && isLogin){
        next("/index");
    };
    if(to.path === "/login"){
        next();
    }else{
        if(isLogin){
            next();
        }else{
            next("/login");
        };
    };
});


export default router