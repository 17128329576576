import Loading from "./loading.vue";
let instance,el;

Loading.install=(Vue)=>{
    Vue.prototype.$QilinLoading={
        show(options={}){
            if(!instance){
                let LoadingInstance=Vue.extend(Loading);
                el=document.createElement("div");
                document.body.appendChild(el);
                instance=new LoadingInstance({
                    propsData:{
                        ...options
                    }
                }).$mount(el);
            }else{
                return instance;
            };
        },
        hide(){
            if(instance){
                // console.log(document.getElementsByClassName("qilin-loading").item(0))
                document.body.removeChild(document.getElementsByClassName("qilin-loading")[0]);
                instance=undefined;
            };
        }
    };
};

export default Loading;
