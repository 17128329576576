/**
 * 设备信息相关操作
 */


export default {
    /**
     * 获取终端设备的语言类型
     */
    getLanguage(){
        return window.navigator.browserLanguage || window.navigator.language;
    },
    /**
     * 获取访问终端设备的类型信息，返回一个对象
     */
    getDevice(){
        const userAgent=window.navigator.userAgent;
        return {
            // 是否是PC端
            PC:!userAgent.match(/AppleWebkit.*Mobile.*/),
            // 是否是移动端
            Mobile:!!userAgent.match(/AppleWebkit.*Mobile.*/),
            // 是否是iPhone
            iPhone:userAgent.includes("iPhone"),
            // 是否是手机
            Phone:(userAgent.includes("Android") && /(?:Mobile)/.test(userAgent)) || 
                userAgent.includes("iPhone") ||
                /(?:Windows Phone)/.test(userAgent),
            // 是否是iPad
            iPad:userAgent.includes("iPad"),
            // 是否是平板电脑
            Tablet:userAgent.includes("iPad") ||
                (userAgent.includes("Android") && !/(?:Mobile)/.test(userAgent)) || 
                (userAgent.includes("Firefox") && /(?:Tablet)/.test(userAgent)),
            WindowsPhone:/(?:Windows Phone)/.test(userAgent)
        };
    },
    /**
     * 获取浏览器的类型信息，返回一个对象
     */
    getBrowser(){
        const userAgent=window.navigator.userAgent;
        return {
            // 是否是edge浏览器
            Edge:!!userAgent.match(/Edg\/([\d.]+)/),
            // 是否是微信内置浏览器
            Wechat:userAgent.includes("MicroMessenger"),
            // 是否是QQ内置浏览器
            QQ:userAgent.includes("QQ"),
            // 是否是QQ浏览器
            QQBrowser:userAgent.includes("MQQBrowser"),
            // 是否是UC浏览器
            UC:userAgent.includes("UCBrowser"),
            // 是否是谷歌浏览器
            Chrome:userAgent.includes("Chrome"),
            // 是否是火狐浏览器
            Firefox:userAgent.includes("Firefox"),
            // 是否是搜狗浏览器
            Sougou:userAgent.toLocaleLowerCase().includes("se 2.x") ||
                userAgent.toLocaleLowerCase().includes("metasr") ||
                userAgent.toLocaleLowerCase().includes("sogou"),
            // 是否是safari浏览器
            Safari:userAgent.includes("Safari") && !userAgent.includes("Chrome")
        };
    },
    /**
     * 获取浏览器内核信息
     */
    getBrowserKernel(){
        const userAgent=window.navigator.userAgent;
        if(userAgent.includes("Presto")){
            return "opera";
        }else if(userAgent.includes("AppleWebKit")){
            return "webkit";
        }else if(userAgent.includes("Gecko") && !userAgent.includes("KHTML")){
            return "gecko";
        }else{
            return null;
        };
    },
    /**
     * 获取操作系统信息
     */
    getOperatingSystem(){
        const userAgent=window.navigator.userAgent;
        return {
            // 是否是windows系统
            Windows:userAgent.includes("Windows"),
            // windows多少位操作系统
            Windows_CPU:(function(){
                if(userAgent.toLocaleLowerCase().includes("win64") || userAgent.toLocaleLowerCase().includes("wow64")){
                    return "x64";
                }else if(userAgent.toLocaleLowerCase().includes("win32") || userAgent.toLocaleLowerCase().includes("wow32")){
                    return "x32";
                }else{
                    return null;
                };
            })(),
            // Windows版本信息
            Windows_Version:(function(){
                if(userAgent.includes("Windows NT 6.1") || userAgent.includes("Window 7")){
                    return "Win7";
                }else if(userAgent.includes("Windows NT 6.3") || userAgent.includes("Windows NT 6.2") || userAgent.includes("Windows NT 8")){
                    return "Win8";
                }else if(userAgent.includes("Windows NT 10") || userAgent.includes("Windows NT 6.4")){
                    return "Win10";
                }else if(userAgent.includes("Windows NT 5.1")){
                    return "WinXP";
                }else{
                    return null;
                };
            })(),
            // 是否是Mac
            Mac:userAgent.includes("Macintosh"),
            // Mac版本
            Mac_Version:(function(){
                if(userAgent.includes("Macintosh")){
                    const matches=userAgent.match(/Mac OS X ([^\s]+)\)/);
                    if(matches && matches[1]){
                        return matches[1].split(/_|\./).join(".");
                    };
                }else{
                    return null;
                };
            })(),
            // 是否是IOS系统
            IOS:!!userAgent.match(/(i[^;]+;( U;)? CPU.+Mac OS X)/),
            // IOS版本
            IOS_Version:(function(){
                if(!!userAgent.match(/(i[^;]+;( U;)? CPU.+Mac OS X)/)){
                    const matches=userAgent.match(/CPU.+OS ([^\s]+) like Mac OS X/);
                    if(matches && matches[1]){
                        return matches[1].split("_|\.").join(".");
                    };
                }else{
                    return null;
                };
            })(),
            // 是否是Android系统
            Android:userAgent.includes("Android"),
            // Android版本
            Android_Version:(function(){
                const matches=userAgent.match(/Android ([^\s]+);/);
                if(matches && matches[1]){
                    return matches[1].split(/_|\./).join(".");
                }else{
                    return null;
                };
            })(),
            // 是否是Linux系统
            Linux:userAgent.includes("Linux"),
            // 是否是鸿蒙系统
            HarmonyOS:userAgent.includes("HarmonyOS"),
            // 是否是Ubuntu系统
            Ubuntu:userAgent.includes("Ubuntu")
        };
    }
};